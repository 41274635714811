<template>
  <v-card
    ref="ticket"
    v-if="order"
    rounded="lg"
    >
    <v-card-text
      class="pa-2"
      id="ticket"
      >
      <div
        class="pa-3 rounded-lg black--text"
        style="border: 2px solid black"
        >
        <div
          class="d-flex flex-row text-subtitle-1"
          >
          <div>
            De: 
          </div>

          <div
            class="pl-3"
            >
            <div>
              {{ order.store.name }}
            </div>

            <div>
              Teléfono: {{ order.store.address.phone }}
            </div>
          </div>
        </div>

        <v-divider
          style="border: 1px solid black"
          class="my-3"
          ></v-divider>

        <div
          class="d-flex align-center"
          >
          <v-img
            class="shrink"
            style="filter: gray; -webkit-filter: grayscale(1);"
            height="150"
            width="150"
            contain
            :src="require('@/assets/full_logo.png')"
            ></v-img>

          <v-divider
            vertical
            style="border: 1px solid black"
            class="mx-3"
            ></v-divider>

          <div>
            <div
              class="d-flex flex-row text-h6"
              >
              <div>
                Para: 
              </div>

              <div
                class="pl-3"
                >
                <div>
                  {{ order.address.fullname }}
                </div>

                <div>
                  {{ order.address.fullAddress }}
                </div>

                <div>
                  Dpto. / Unidad: {{ order.address.unit }}
                </div>

                <div>
                  Teléfono: {{ order.address.phone }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <v-divider
          style="border: 1px solid black"
          class="my-3"
          ></v-divider>

        <div
          class="d-flex justify-space-between"
          >
          <canvas
            id="canvas"
            ></canvas>

          <img 
            id="barcode"
            />
        </div>

        <v-divider
          style="border: 1px solid black"
          class="my-3"
          ></v-divider>

        <div
          class="text-subtitle-1 d-flex flex-wrap justify-space-between"
          >
          <div>
            Orden número: {{ order.number }}
          </div>

          <div
            class="px-3"
            ></div>

          <div>
            Envío número: {{ order.shipments[order.shipments.length - 1].number }}
          </div>
        </div>
      </div>
    </v-card-text>

    <v-card-actions
      class="justify-end"
      >
      <v-btn
        text
        color="secondary"
        @click="printPdf"
        >
        Descargar etiqueta
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Order } from '@/graphql/queries/orders'
import html2canvas from 'html2canvas'
import { jsPDF } from "jspdf"

import JsBarcode from 'jsbarcode'

export default {
  data: () => ({
    order: null
  }),

  props: {
    orderToken: {
      type: String | Number,
      required: true
    }
  },

  created () {
    this.fetchOrder()
  },

  methods: {
    fetchOrder () {
      this.$apollo.query({
        query: Order,
        variables: {
          orderToken: this.orderToken
        }
      }).then ( res => {
        this.order = res.data.order

        this.$nextTick( () => {
          var QRCode = require('qrcode')
          var canvas = document.getElementById('canvas')

          var shipment = this.order.shipments[this.order.shipments.length - 1]

          JsBarcode("#barcode", shipment.number, {
            textMargin: 0,
            displayValue: false,
            width: 3
          });

          QRCode.toCanvas(canvas, shipment.number, {
            scale: '6',
            margin: '0'
          }, function (error) {
            if (error) console.error(error)
          })
        })
      })
    },

    printPdf () {
      const ticket = document.getElementById("ticket")
      html2canvas(ticket, { useCORS: true, width: ticket.clientWidth, height: ticket.clientHeight }).then(canvas => {
        var img = canvas.toDataURL("image/png");

        var doc = new jsPDF(
          'l',
          'px',
          [ticket.clientWidth, ticket.clientHeight]
        );

        doc.addImage(img, 'png', 0, 0, ticket.clientWidth, ticket.clientHeight);
        doc.save(`etiqueta_envio_${this.order.shipments[this.order.shipments.length - 1].number}.pdf`);
      })
    }
  }
}
</script>
