<template>
  <div>
    <div
      class="d-flex flex-row"
      style="gap: 8px"
      >
      <div
        style="flex: 1"
        >
        <v-text-field
          outlined
          class="rounded-lg"
          v-model="search"
          clearable
          label="Buscar orden"
          placeholder="Buscar por número de orden o por producto"
          append-icon="mdi-magnify"
          @click:append="fetchOrders"
          @keydown.enter="fetchOrders"
          ></v-text-field>
      </div>

      <div>
        <v-btn
          fab
          depressed
          class="rounded-lg"
          color="green darken-1"
          @click="downloadReport"
          >
          <v-icon
            color="white"
            >
            mdi-file-excel
          </v-icon>
        </v-btn>
      </div>
    </div>

    <template
      v-for="(order, i) in orders"
      >
      <v-card
        @click="showDetail(order)"
        class="mb-3"
        horizontal
        :outlined="outlined"
        >
        <v-card-text
          class="d-flex justify-space-between"
          >
          <div>
            <div
              class="text-subtitle-2"
              >
              {{ order.number }}
            </div>

            <div
              class="caption"
              >
              {{ order.lineItems.length }} ítem(s)
            </div>

            <div
              class="caption"
              >
              Fecha de creación: {{ (new Date(order.createdAt)).toLocaleDateString() }}
            </div>
          </div>

          <div
            class="d-flex align-center justify-end"
            >
            <div
              class="d-flex flex-column align-end"
              >
              <div
                class="font-weight-medium"
                >
                {{ order.displayTotalAmount }}
              </div>

              <div>
                <v-chip
                  x-small
                  :color="order.state == 'completed' ? 'success' : ''"
                  >
                  {{ $t('states.' + order.state) }}
                </v-chip>
              </div>
            </div>

            <div
              class="ml-3"
              v-if="order.state == 'completed'"
              >
              <v-btn
                color="secondary"
                icon
                @click.stop="printTicket(order)"
                >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>

    <div 
      v-if="pages > 1"
      class="text-center">
      <v-pagination
        v-model="page"
        :length="pages"
        ></v-pagination>
    </div>
    
    <v-dialog
      v-model="ticketDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      scrollable
      width="600px"
      >
      <ticket
        :orderToken="order.orderToken"
        :key="order.id"
        :close="true"
        @close="ticketDialog = false"
        ></ticket>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      scrollable
      width="800px"
      >
      <Detail
        :currentOrder="order"
        :key="order.id"
        :close="true"
        :admin="admin"
        :checkout="false"
        @close="dialog = false"
        />
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

import { Orders } from '@/graphql/queries/orders'
import Detail from '@/components/orders/Detail'
import Ticket from '@/components/shippings/Ticket'

export default {
  data () {
    return {
      order: {},
      orders: [],
      dialog: false,
      page: 1,
      pages: 0,
      search: null,
      timer: null,
      ticketDialog: false,
    }
  },

  props: {
    outlined: {
      required: false,
      default: false,
      type: Boolean
    },
    admin: {
      required: false,
      default: true,
      type: Boolean
    }
  },
  
  mounted () {
    this.fetchOrders()
  },

  components: {
    Detail, Ticket
  },

  watch: {
    search () {
      if (this.timer) { clearTimeout(this.timer) }

      this.timer = setTimeout( () => {
        this.page = 1
      }, 300)
    },

    page () {
      this.fetchOrders()
    }
  },

  methods: {
    fetchOrders () {
      this.$apollo.query({
        query: Orders,
        variables: {
          admin: this.admin,
          search: this.search,
          page: this.page
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.orders = res.data.orders.orders
        this.pages = res.data.orders.pages
      })
    },

    showDetail (order) {
      this.order = order
      this.dialog = true
    },

    printTicket (order) {
      this.order = order
      this.ticketDialog = true
    },

    downloadReport () {
      axios({
        url: process.env.VUE_APP_RAILS_URL + '/orders?admin=true', //your url
        method: 'GET',
        headers: {
          'authorization': localStorage.getItem('auth_token')
        },
        responseType: 'blob', // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'report.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    }
  }
}
</script>
