<template>
  <v-container
    style="max-width: 1200px"
    >
    <Header
      title="Órdenes" />
    <List />
  </v-container>
</template>

<script>
import List from '@/components/admin/orders/List'
import Header from '@/components/admin/shared/Header'

export default {
  components: {
    List,
    Header
  }
}
</script>
